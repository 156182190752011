<template>
  <i :class="iconClass"></i>
</template>

<script lang="ts">
export default {
  props: {
    icon: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    iconClass() {
      return this.icon.join(' ');
    },
  },
};
</script>